import { useRoute, useRouter } from '#imports';

export function useQueryParams() {
  const queryParamsStore = useQueryParamsStore();
  const route = useRoute();
  const router = useRouter();

  // Extract query params from the current route
  const captureQueryParams = async () => {
    const queryParams = { ...route.query };
    if(queryParams && !_isEmpty(queryParams)) {
      queryParamsStore.setQueryParams(queryParams as Record<string, string>);
      storeAgentInfo(queryParams as Record<string, string>);
    }
  };

  async function storeAgentInfo(queryParams: Record<string, string>) {
    const agentId = getIgnoreCase(queryParams, "agent", "")
    if(agentId) {
      fetch(`/webapi/agents/${agentId}`).then(async response => {
        const agentInfo = await response.json();
        if(_get(agentInfo, "id")) {
          useAgentInfo().setAgentInfoInStore(agentInfo);
        } else {
          useAgentInfoStore().reset()
        }
      }).catch(error => {
        useAgentInfoStore().reset()
      })
    }
  }

  // Initialize and load stored params from session storage if available
  const loadStoredQueryParams = () => {
    let queryParams = useQueryParamsStore().queryParams
    if(isProxy(queryParams)) {
      queryParams = toRaw(queryParams);
    }
    if (Object.keys(queryParams).length > 0) {
      // Optionally sync the stored params with the current route
      router.replace({ query: queryParams });
    }
  };

  // Sync on route change
  const syncQueryParamsOnNavigate = () => {
    watch(
      () => route.query,
      (newQuery) => {
        captureQueryParams();
      },
      { immediate: true }
    );
  };

  function getIgnoreCase<T>(object: Record<string, any> | null, path: string | string[], defaultValue: T): T | any {
    if (!object) return defaultValue;

    const paths = Array.isArray(path) ? path : path.split('.');
    let current: any = object;

    for (const key of paths) {
      if (current) {
        const lowerKey = String(key).toLowerCase();
        const foundKey = Object.keys(current).find(k => k.toLowerCase() === lowerKey);

        if (foundKey) {
          current = current[foundKey];
        } else {
          return defaultValue;
        }
      } else {
        return defaultValue;
      }
    }

    return current;
  }

  return { captureQueryParams, loadStoredQueryParams, syncQueryParamsOnNavigate };
}
