import { ref } from 'vue'
import { useFetch } from '#app'

export function useAgentInfo() {
  const agentInfo = ref({})
  const loading = ref(true);
  const error = ref<string | null>(null);

  const getAgentInfo = async (agentId: string | number) => {
    if (!agentId) {
      throw createError({
        statusCode: 400,
        statusMessage: 'Agent ID is required',
      })
    }

    try {
      const { data, error: agentError } = await useFetch(`/webapi/agents/${agentId}`, {
        method: 'GET',
      })

      if (agentError.value) {
        error.value = agentError.value.message
      } else {
        agentInfo.value = data.value || {}
      }
    } catch (error: any) {
      error.value = error.message;
    } finally {
      loading.value = false;
    }
    return agentInfo;
  }

  const setAgentInfoInStore = (agentInfo: any) => { 
    const isSplitAgent = ref<boolean>(false);
    if(!_isEmpty(agentInfo)) {
      let insuranceAgent = {
        commissionPercentage: parseFloat(_get(agentInfo , "properties.commission_split"))*100,
        emailAddress: _get(agentInfo , "properties.email"),
        identifier: _get(agentInfo , "properties.agent_rec_id"),
        name: {
          first: _get(agentInfo , "properties.firstname"),
          last: _get(agentInfo , "properties.lastname"),
          middle: '',
          suffix: ''
        }
      }
      isSplitAgent.value = _get(agentInfo , "properties.split_yes___no") === "Yes";
      useAgentInfoStore().setInsuranceAgent(insuranceAgent)
      useAgentInfoStore().setIsSplitAgent(isSplitAgent.value)
    }
  }

  return { getAgentInfo, agentInfo, loading, error, setAgentInfoInStore}
}
